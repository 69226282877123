import CategoryContainer from 'components/Podcast/primitives/CategoryContainer';
import CategoryOverlay from 'components/Podcast/primitives/CategoryOverlay';
import CategoryTile from 'components/Podcast/primitives/CategoryTile';
import Image from 'components/Image';
import NavLink from 'components/NavLink';
import PageBody, { ViewName } from 'views/PageBody';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Network } from 'state/Podcast/types';

type Props = {
  networks: Array<Network>;
};

const NetworkDirectory = ({ networks }: Props) => {
  const translate = useTranslate();

  return (
    <PageBody
      dataTest={ViewName.PodcastNetworkDirectory}
      hideFooter
      title={translate('iHeart Podcast Networks')}
    >
      <CategoryContainer>
        {networks.map(network => (
          <CategoryTile key={network.title}>
            <NavLink to={network.link.urls.web}>
              <Image
                alt={network.title}
                aspectRatio={1.77}
                background
                src={network.img_uri}
              />
              <CategoryOverlay />
            </NavLink>
          </CategoryTile>
        ))}
      </CategoryContainer>
    </PageBody>
  );
};

export default NetworkDirectory;
